import React from 'react'
import Logo from '../assets/images/logo.webp'

const Header = () => {
  return (
		<header id="header"
			data-plugin-options="{'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': false, 'stickyStartAt': 164, 'stickySetTop': '-164px', 'stickyChangeLogo': false}">
			<div className="header-body border-0">

				<div className="header-container container">
					<div className="header-row ">
						<div className="header-column justify-content-center justify-content-md-center order-1 order-md-2">
							<div className="header-row">
								<div className="header-logo mt-1 mb-1">
									<a href="index.html">
										<img alt="Porto" width="150" height="35" src={Logo} />
									</a>
								</div>
							</div>
						</div>

					</div>
				</div>

			</div>
		</header>
  )
}

export default Header