import React, { lazy } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/theme.css';
import '../styles/skin-corporate.css';
import '../styles/theme-elements.css';

import Footer from "./Footer";
import Header from "./Header";
import Video from "./Video";

const Banner = lazy(() => import('./Banner'));
const BulletPoints = lazy(() => import('./BulletPoints'));
const CompetitiveDifferential = lazy(() => import('./CompetitiveDifferential'));
const Carousel = lazy(() => import('./Carousel'));
const FAQ = lazy(() => import('./FAQ'));
const LimitedPotentialOffer = lazy(() => import('./LimitedPotentialOffer'));
const LimitedShineOffer = lazy(() => import('./LimitedShineOffer'));

function App() {
  const shopURL = "https://a355eb.myshopify.com/cart/40264270872638:1?channel=buy_button";
  const presentationVideoURL = "vYTW63dbdJo";

  return (
    <div className="App">
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"
        integrity="sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM"
        crossOrigin="anonymous"
      />
      <Header />
      <div role="main" className="main">
        <Banner shopURL={shopURL} />
        <Video presentationVideoURL={presentationVideoURL} />
        <CompetitiveDifferential shopURL={shopURL} />
        <LimitedShineOffer shopURL={shopURL} />
        <Carousel />
        <LimitedPotentialOffer shopURL={shopURL} />
        <BulletPoints shopURL={shopURL} />

        <hr className="solid my-5"/>

        <FAQ />
      </div>
      <Footer />
    </div>
  );
}

export default App;
