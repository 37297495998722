import React from 'react'

import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const Video = ({presentationVideoURL}) => {
  return (
    <div className="home-intro mb-0 bg-color-tertiary" id="home-intro">
      <div className="container">
        <div className="row text-center">
          <div className="col">
            <h1 className="text-color-light font-weight-bold text-6 line-height-1 mb-5 "> One time purchase!
            </h1>
          </div>
        </div>

        <div className="row mb-5 justify-content-center">
          <div className="col-lg-6 position-relative">
            <div className="ratio ratio-16x9 ratio-borders">
              <LiteYouTubeEmbed
                id={presentationVideoURL} // Default none, id of the video or playlist
                frameBorder="0"
                title="yt-presentation-video"
                poster="maxresdefault" // Defines the image size to call on first render as poster image. Possible values are "default","mqdefault",  "hqdefault", "sddefault" and "maxresdefault". Default value for this prop is "hqdefault". Please be aware that "sddefault" and "maxresdefault", high resolution images are not always avaialble for every video. See: https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
                params="" // any params you want to pass to the URL, assume we already had '&' and pass your parameters string
                adNetwork={true} // Default true, to preconnect or not to doubleclick addresses called by YouTube iframe (the adnetwork from Google)
                playlist={false} // Use true when your ID be from a playlist
                noCookie={true} // Default false, connect to YouTube via the Privacy-Enhanced Mode using https://www.youtube-nocookie.com
              />
            </div>
          </div>
        </div>
        <div className="row text-center mb-5">
          <div className="col">
            <p className="mb-0">
              <span>Ensure full access for life. All updates and new features will be available to you
                forever if you buy <strong className="font-weight-bold"> now</strong></span>
            </p>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Video